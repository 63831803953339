<template>
  <base-layout>
    <ServiceAreaIndex
      :pageTitle="$t('components.serviceAreaManagement.headline')"
    />
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ServiceAreaIndex from '@/views/geofence/service-area/index.vue'

export default {
  name: 'BillingPlans',

  components: {
    BaseLayout,
    ServiceAreaIndex,
  },
}
</script>
