var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('add-edit-service-area'),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10"},[(_vm.isShowOnlyPageTitle)?_c('div',[_c('oto-page-title',{attrs:{"title":_vm.pageTitle}})],1):_c('title-plus',{attrs:{"title":_vm.pageTitle},on:{"plus":_vm.add}})],1),_c('div',{staticClass:"flex flex-col mt-4 mb-10 space-x-0 space-y-4 md:space-x-4 md:space-y-0 md:items-center md:flex-row"},[_c('SummaryCard',{attrs:{"title":_vm.$t('components.serviceAreaManagement.summary.totalServiceArea'),"value":_vm.indexMetaData.summary.total,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.serviceAreaManagement.summary.vehicleAssigned'),"value":_vm.indexMetaData.summary.vehicles_assigned,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.serviceAreaManagement.summary.insideVehicles'),"value":_vm.indexMetaData.summary.vehicles_inside,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.serviceAreaManagement.summary.outsideVehicles'),"value":_vm.indexMetaData.summary.vehicles_outside,"variant":"gray"}})],1)]),(_vm.$acl.canNotView('Service Areas'))?_c('div',{staticClass:"py-5 font-bold text-center text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t('components.acl.doNotHavePermission'))+" ")]):_c('FSTable',{attrs:{"fst-id":"ServiceAreaIndex","headers":_vm.getTableHeaders,"qso":_vm.qso,"endpoint":_vm.endpoint,"isMultipleViewEnabled":true},on:{"meta":function (e) { return (_vm.indexMetaData = e); },"view":function (v) { return (_vm.layoutView = v); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [(_vm.layoutView === 'list')?[_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewServiceArea',
                      params: { id: item.id },
                    }}},[_vm._v(" #"+_vm._s(_vm._f("onlyLastFive")(item.id))+" ")])],1),_c('FSTableRowItem',{staticClass:"flex"},[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewServiceArea',
                      params: { id: item.id },
                    }}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.is_default_service_area_for_swapping)?_c('div',{staticClass:"flex relative w-5 h-5 ml-2"},[_c('span',{staticClass:"inline-flex absolute w-full h-full bg-green-500 rounded-full opacity-75 animate-ping"}),_c('span',{staticClass:"inline-flex relative mt-1 ml-1 w-3 h-3 rounded-full bg-oGreen"})]):_vm._e()],1),_c('FSTableRowItem',{attrs:{"text":item.fleet ? item.fleet.name : '--'}}),_c('FSTableRowItem',{attrs:{"text":item.vehicles_inside}}),_c('FSTableRowItem',[_c('x-status',{attrs:{"variant":item.is_active ? 'green' : 'gray',"text":item.is_active ? 'Active' : 'Inactive',"profile":"fleet"}})],1),_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.edit(item)}}}),_c('MoreActionsDropdown',{key:("more-actions-" + itemIndex),attrs:{"type":"serviceArea","data":item,"isMakeDefault":true},on:{"exportKML":function($event){return _vm.handleExportKML(item)},"change":function($event){return _vm.handleAction('change', itemIndex, $event)},"delete":function($event){return _vm.handleAction('delete', itemIndex, $event)}}})],1)])],1)]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',{staticClass:"flex"},[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewServiceArea',
                      params: { id: item.id },
                    }}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.is_default_service_area_for_swapping)?_c('div',{staticClass:"flex relative w-5 h-5 ml-2"},[_c('span',{staticClass:"inline-flex absolute w-full h-full bg-green-500 rounded-full opacity-75 animate-ping"}),_c('span',{staticClass:"inline-flex relative mt-1 ml-1 w-3 h-3 rounded-full bg-oGreen"})]):_vm._e()],1),_c('FSTableRowItem',[_c('x-status',{attrs:{"variant":item.is_active ? 'green' : 'gray',"text":item.is_active ? 'Active' : 'Inactive',"profile":"fleet"}})],1),_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.edit(item)}}}),_c('MoreActionsDropdown',{key:("more-actions-" + itemIndex),attrs:{"type":"serviceArea","data":item,"isMakeDefault":true},on:{"exportKML":function($event){return _vm.handleExportKML(item)},"change":function($event){return _vm.handleAction('change', _vm.index, $event)},"delete":function($event){return _vm.handleAction('delete', _vm.index, $event)}}})],1)])],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Id")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                          name: 'ViewServiceArea',
                          params: { id: item.id },
                        }}},[_vm._v(" #"+_vm._s(_vm._f("onlyLastFive")(item.id))+" ")])],1)]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Assigned Fleet")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.fleet ? item.fleet.name : '--')+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Vehicles Inside")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.vehicles_inside)+" ")])])])]):_vm._e()]:_vm._e()]})]:_vm._e(),(_vm.layoutView === 'grid')?_vm._l((data),function(item,itemIndex){return _c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableGridItem',[_c('div',{staticClass:"bg-gray-50 rounded-lg flex justify-between items-center p-1"},[_c('div',{staticClass:"flex"},[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewServiceArea',
                      params: { id: item.id },
                    }}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.is_default_service_area_for_swapping)?_c('div',{staticClass:"flex relative w-4 h-4 ml-2 mt-1"},[_c('span',{staticClass:"inline-flex absolute w-full h-full bg-green-500 rounded-full opacity-75 animate-ping"}),_c('span',{staticClass:"inline-flex relative mt-1 ml-1 w-2 h-2 rounded-full bg-oGreen"})]):_vm._e()],1),_c('div',{staticClass:"flex items-center"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.edit(item)}}}),_c('MoreActionsDropdown',{key:("more-actions-" + itemIndex),attrs:{"type":"serviceArea","data":item,"isMakeDefault":true},on:{"exportKML":function($event){return _vm.handleExportKML(item)},"change":function($event){return _vm.handleAction('change', itemIndex, $event)},"delete":function($event){return _vm.handleAction('delete', itemIndex, $event)}}})],1)]),_c('div',{staticClass:"h-52"},[_c('MapView',{attrs:{"data":item}})],1)])],1)}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }