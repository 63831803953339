var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-over-right',{attrs:{"id":_vm.sorId,"full-width":true}},[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full md:w-1/2 "},[_c('div',{staticClass:"overflow-y-auto",staticStyle:{"height":"calc(100vh - 70px )"}},[_c('div',{staticClass:"flex items-center pl-8 my-1 md-max:pl-4"},[_c('div',{staticClass:"items-center justify-center cursor-pointer back-button",on:{"click":_vm.goBack}},[_c('i',{staticClass:"fas fa-arrow-left"})]),_c('h2',{staticClass:"ml-3 font-semibold text-gray-900 text-22px"},[_vm._v(" "+_vm._s(_vm.titleText)+" ")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmSubmit)}}},[_c('div',{staticClass:"grid grid-cols-1 gap-2 mt-1 lg:grid-cols-2"},[_c('text-input',{attrs:{"rules":"required","name":_vm.$t(
                    'components.serviceAreaManagement.addEdit.steps.details.title.areaName'
                  ),"label":_vm.$t(
                    'components.serviceAreaManagement.addEdit.steps.details.title.areaName'
                  )},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('ValidationProvider',{attrs:{"name":_vm.$t(
                    'components.serviceAreaManagement.addEdit.steps.details.title.assignedFleet'
                  ),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                      'components.serviceAreaManagement.addEdit.steps.details.title.assignedFleet'
                    )}}),_c('t-rich-select',{attrs:{"name":"options","options":_vm.serviceAreaFleets,"valueAttribute":"id","textAttribute":"name","hideSearchBox":false,"variant":{ danger: !!errors[0] }},on:{"input":_vm.onChangeFleet},model:{value:(_vm.form.fleet),callback:function ($$v) {_vm.$set(_vm.form, "fleet", $$v)},expression:"form.fleet"}}),_c('div',{},[_c('input-error-item',{attrs:{"message":errors[0]}})],1)]}}],null,true)})],1),_c('div',{staticClass:"flex items-end gap-2 mt-1"},[_c('div',{staticClass:"w-full"},[_c('PlacesAutocomplete',{attrs:{"label":_vm.$t(
                      'components.serviceAreaManagement.addEdit.steps.details.title.searchForLocation'
                    ),"disabled":!!!_vm.form.fleet,"placeholder":!!_vm.form.fleet === false
                      ? _vm.$t(
                          'components.serviceAreaManagement.addEdit.steps.details.placeHolder.fleetAtFirst'
                        )
                      : _vm.$t(
                          'components.serviceAreaManagement.addEdit.steps.details.placeHolder.enterAddress'
                        )},on:{"update:place":_vm.onUpdatingPlace,"clear:place":function () { return (_vm.place = null); }}})],1),_c('UploadGeoFile',{on:{"input":_vm.addKmlLayer}})],1),_c('div',{staticClass:"grid grid-cols-1 gap-2 mt-5 lg:grid-cols-2 lg:flex lg:items-start"},[_c('div',{staticClass:"w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                      'components.serviceAreaManagement.addEdit.steps.details.title.speedLimit'
                    ),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                        'components.serviceAreaManagement.addEdit.steps.details.title.speedLimit'
                      )}}),_c('t-rich-select',{attrs:{"name":"options","options":_vm.serviceAreaSpeedLimits,"valueAttribute":"id","textAttribute":"speed_choice","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.speed_limit),callback:function ($$v) {_vm.$set(_vm.form, "speed_limit", $$v)},expression:"form.speed_limit"}}),_c('div',{},[_c('input-error-item',{attrs:{"message":errors[0]}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"grid grid-cols-1 gap-2 mt-1 lg:grid-cols-2 lg:flex lg:items-end"},[_c('div',{staticClass:"w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                      'components.serviceAreaManagement.addEdit.steps.details.title.allowOutsideOfServiceAreaSpeedModeChange'
                    ),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                        'components.serviceAreaManagement.addEdit.steps.details.title.allowOutsideOfServiceAreaSpeedModeChange'
                      ),"isShowInfoSymbol":true,"info":_vm.$t(
                        'components.serviceAreaManagement.addEdit.steps.details.infoDescription.allowOutsideOfServiceAreaSpeedModeChange'
                      )}}),_c('t-rich-select',{attrs:{"name":"options","options":[
                      { value: true, text: 'Yes' },
                      { value: false, text: 'No' } ],"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.reset_speed_mode_for_exiting),callback:function ($$v) {_vm.$set(_vm.form, "reset_speed_mode_for_exiting", $$v)},expression:"form.reset_speed_mode_for_exiting"}}),_c('div',{},[_c('input-error-item',{attrs:{"message":errors[0]}})],1)]}}],null,true)})],1),(_vm.form.reset_speed_mode_for_exiting)?_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                    'components.serviceAreaManagement.addEdit.steps.details.title.exitSpeedMode'
                  ),"label":_vm.$t(
                    'components.serviceAreaManagement.addEdit.steps.details.title.exitSpeedMode'
                  ),"placeholder":_vm.$t(
                    'components.serviceAreaManagement.addEdit.steps.details.placeHolder.exitSpeedMode'
                  ),"options":[
                  { value: 'L', text: 'Low' },
                  { value: 'M', text: 'Medium' },
                  { value: 'H', text: 'High' } ],"hide-search-box":""},model:{value:(_vm.form.exit_speed_mode),callback:function ($$v) {_vm.$set(_vm.form, "exit_speed_mode", $$v)},expression:"form.exit_speed_mode"}}):_vm._e()],1),_c('section',[_c('div',{staticClass:"grid grid-cols-1 gap-2 mt-1 lg:grid-cols-2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                      'components.serviceAreaManagement.addEdit.steps.details.title.vehicleGoesOutsideOfBoundaryThrottle'
                    ),"label":_vm.$t(
                      'components.serviceAreaManagement.addEdit.steps.details.title.vehicleGoesOutsideOfBoundaryThrottle'
                    ),"placeholder":_vm.$t(
                      'components.serviceAreaManagement.addEdit.steps.details.placeHolder.vehicleGoesOutsideOfBoundaryThrottle'
                    ),"value-attribute":"value","text-attribute":"text","options":[
                    { text: 'On', value: true },
                    { text: 'Off', value: false } ],"hide-search-box":""},model:{value:(_vm.form.should_throttle),callback:function ($$v) {_vm.$set(_vm.form, "should_throttle", $$v)},expression:"form.should_throttle"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                      'components.serviceAreaManagement.addEdit.steps.details.title.vehicleGoesOutsideOfBoundaryAlarm'
                    ),"label":_vm.$t(
                      'components.serviceAreaManagement.addEdit.steps.details.title.vehicleGoesOutsideOfBoundaryAlarm'
                    ),"placeholder":_vm.$t(
                      'components.serviceAreaManagement.addEdit.steps.details.placeHolder.vehicleGoesOutsideOfBoundaryAlarm'
                    ),"value-attribute":"value","text-attribute":"text","options":[
                    { text: 'Trigger', value: true },
                    { text: 'Do not Trigger', value: false } ],"hide-search-box":""},model:{value:(_vm.form.should_trigger),callback:function ($$v) {_vm.$set(_vm.form, "should_trigger", $$v)},expression:"form.should_trigger"}})],1)]),(_vm.isEditing)?_c('section',[_c('div',{staticClass:"grid grid-cols-1  mt-1"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                      'components.serviceAreaManagement.addEdit.steps.details.title.assignDefaultRentStation'
                    ),"label":_vm.$t(
                      'components.serviceAreaManagement.addEdit.steps.details.title.assignDefaultRentStation'
                    ),"value-attribute":"value","text-attribute":"text","options":_vm.getRentStationList,"placeholder":_vm.getRentStationPlaceholder,"disabled":_vm.getRentStationDisabledState,"hide-search-box":""},model:{value:(_vm.form.default_rent_area.id),callback:function ($$v) {_vm.$set(_vm.form.default_rent_area, "id", $$v)},expression:"form.default_rent_area.id"}})],1),(!_vm.isRentStationLoading && _vm.getRentStationList.length < 2)?_c('p',{staticClass:"text-sm"},[_c('RouterLink',{staticClass:"text-blue-600 capitalize font-semibold",attrs:{"to":{
                    name: 'RentStationIndex',
                  },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t( 'components.serviceAreaManagement.addEdit.steps.details.placeHolder.assignDefaultRentStation.createRentStation' ))+" ")]),_vm._v(" "+_vm._s(_vm.$t( 'components.serviceAreaManagement.addEdit.steps.details.placeHolder.assignDefaultRentStation.atFirst' ))+" ")],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"w-full"},[_c('AppInput',{attrs:{"rules":"","name":_vm.$t(
                    'components.serviceAreaManagement.addEdit.steps.details.title.note'
                  ),"label":_vm.$t(
                    'components.serviceAreaManagement.addEdit.steps.details.title.note'
                  )},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('section',[_c('input-label',{staticClass:"my-2 mt-4 font-bold",attrs:{"text":_vm.$t(
                    'components.serviceAreaManagement.addEdit.steps.details.title.serviceAreaSpotPhoto'
                  )}}),_c('div',{staticClass:"flex items-center overflow-x-auto md-max:flex-wrap"},[(_vm.form.photo && !_vm.previewPhoto)?_c('div',{staticClass:"mr-4 h-20 w-20"},[_c('div',{staticClass:"w-full h-full"},[_c('img',{staticClass:"object-cover w-full h-full rounded",attrs:{"src":_vm.item.photo}})])]):_vm._e(),(_vm.previewPhoto)?_c('div',{staticClass:"mr-4 h-20 w-20"},[_c('div',{staticClass:"w-full h-full"},[_c('img',{staticClass:"object-cover w-full h-full rounded",attrs:{"src":_vm.previewPhoto}})])]):_vm._e(),_c('upload-image',{attrs:{"title":"Add Photo"},on:{"input":_vm.addNewImage,"preview-image":_vm.showPreview}})],1)],1),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])])]}}])})],1),_c('div',{staticClass:"flex items-center justify-end pr-6 mt-6"},[_c('slide-over-right-trigger',{attrs:{"id":_vm.sorId}},[_c('anchor-button',{attrs:{"variant":"secondary"},on:{"click":_vm.onRefresh}},[_vm._v(_vm._s(_vm.$t('components.stepNavigation.cancel')))])],1),_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.$t('components.stepNavigation.save')))])],1)]),_c('div',{staticClass:"w-full mt-6 md:w-1/2 md:mt-0"},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"100vh"},attrs:{"zoom":_vm.zoom,"center":_vm.center,"map-type-id":"terrain"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }