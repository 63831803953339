<template>
  <button
    class="oto-edit-button focus:outline-none focus:shadow-none"
    @click="handleClicking"
  >
    <svg
      class="w-4 h-5"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
      ></path>
    </svg>
    <span class="ml-2 font-medium text-oBlack">
      {{ $t('components.stepNavigation.edit') }}</span
    >
  </button>
</template>
<script>
export default {
  name: 'OtoEditButton',
  methods: {
    handleClicking() {
      this.$emit('click')
    },
  },
}
</script>
<style lang="sass" scoped>
$edit-btn-bg: #f3f3f3

.oto-edit-button
  display: flex
  align-items: center
  justify-content: center
  width: 96px
  height: 42px
  padding: 4px 23px 5px 12px
  border-radius: 30px
  background-color: $edit-btn-bg
  cursor: pointer
  &:hover
    background: lighten($edit-btn-bg, 2%)
    &:focus
      outline: none
      appearance: none
      shadow: none
</style>
