import xmlbuilder from 'xmlbuilder'
import { saveAs } from 'file-saver'

export function parseToOverlay(coords) {
  if (!coords) return
  var coordsMorph = coords.replaceAll('{', '')
  coordsMorph = coordsMorph.replaceAll('}', '')
  var coordsArr = coordsMorph.split(',')
  if (!Array.isArray(coordsArr) || !coordsArr.length % 2 === 0) {
    if (coordsArr[coordsArr.length - 1] == ',') {
      console.log('Hit pop')
      coordsArr.pop()
    }
  }
  // console.log("")
  var payload = []
  for (var i = 0; i < coordsArr.length; i = i + 2) {
    const lat = parseFloat(coordsArr[i])
    const lng = parseFloat(coordsArr[i + 1])
    if (isNaN(lat) || isNaN(lng)) continue
    payload.push({
      lat: lat,
      lng: lng,
    })
  }
  return payload
}

export function createKML(coordinates) {
  const root = xmlbuilder.create('kml', {
    version: '1.0',
    encoding: 'UTF-8',
  })
  root.attribute('xmlns', 'http://www.opengis.net/kml/2.2')

  const documentNode = root.ele('Document')
  const placemarkNode = documentNode.ele('Placemark')
  const polygonNode = placemarkNode.ele('Polygon')
  const outerBoundaryIsNode = polygonNode.ele('outerBoundaryIs')
  const linearRingNode = outerBoundaryIsNode.ele('LinearRing')

  const coordinatesText = coordinates
    .map((coord) => `${coord.lng},${coord.lat},0`)
    .join(' ')
  linearRingNode.ele('coordinates', coordinatesText)

  return root.end({ pretty: true })
}

export function downloadKMLFile(name, kmlString) {
  const blob = new Blob([kmlString], {
    type: 'application/vnd.google-earth.kml+xml',
  })
  const modifiedName = name.replace(' ', '_').replace('-', '_')
  saveAs(blob, `${modifiedName}.kml`)
}
