<template>
  <section>
    <add-edit-service-area />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10">
          <div v-if="isShowOnlyPageTitle">
            <oto-page-title :title="pageTitle" />
          </div>
          <title-plus v-else :title="pageTitle" @plus="add" />
        </div>

        <div
          class="flex flex-col mt-4 mb-10 space-x-0 space-y-4 md:space-x-4 md:space-y-0 md:items-center md:flex-row"
        >
          <SummaryCard
            :title="
              $t('components.serviceAreaManagement.summary.totalServiceArea')
            "
            :value="indexMetaData.summary.total"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t('components.serviceAreaManagement.summary.vehicleAssigned')
            "
            :value="indexMetaData.summary.vehicles_assigned"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t('components.serviceAreaManagement.summary.insideVehicles')
            "
            :value="indexMetaData.summary.vehicles_inside"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t('components.serviceAreaManagement.summary.outsideVehicles')
            "
            :value="indexMetaData.summary.vehicles_outside"
            variant="gray"
          />
        </div>
      </div>
      <div
        v-if="$acl.canNotView('Service Areas')"
        class="py-5 font-bold text-center text-gray-600"
      >
        {{ $t('components.acl.doNotHavePermission') }}
      </div>

      <FSTable
        v-else
        :fst-id="`ServiceAreaIndex`"
        :headers="getTableHeaders"
        :qso="qso"
        :endpoint="endpoint"
        :isMultipleViewEnabled="true"
        @meta="(e) => (indexMetaData = e)"
        @view="(v) => (layoutView = v)"
      >
        <template #default="{ data }">
          <template v-if="layoutView === 'list'">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow :key="itemIndex" text-fallback-always>
                  <FSTableRowItem>
                    <router-link
                      class="font-bold text-blue-600 capitalize"
                      :to="{
                        name: 'ViewServiceArea',
                        params: { id: item.id },
                      }"
                    >
                      #{{ item.id | onlyLastFive }}
                    </router-link>
                  </FSTableRowItem>

                  <FSTableRowItem class="flex">
                    <router-link
                      class="font-bold text-blue-600 capitalize"
                      :to="{
                        name: 'ViewServiceArea',
                        params: { id: item.id },
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                    <div
                      v-if="item.is_default_service_area_for_swapping"
                      class="flex relative w-5 h-5 ml-2"
                    >
                      <span
                        class="inline-flex absolute w-full h-full bg-green-500 rounded-full opacity-75 animate-ping"
                      />
                      <span
                        class="inline-flex relative mt-1 ml-1 w-3 h-3 rounded-full bg-oGreen"
                      />
                    </div>
                  </FSTableRowItem>

                  <FSTableRowItem :text="item.fleet ? item.fleet.name : '--'" />

                  <FSTableRowItem :text="item.vehicles_inside" />

                  <FSTableRowItem>
                    <x-status
                      :variant="item.is_active ? 'green' : 'gray'"
                      :text="item.is_active ? 'Active' : 'Inactive'"
                      profile="fleet"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <div class="flex items-center">
                      <oto-edit-icon @click="edit(item)" />
                      <MoreActionsDropdown
                        :key="`more-actions-${itemIndex}`"
                        :type="`serviceArea`"
                        :data="item"
                        :isMakeDefault="true"
                        @exportKML="handleExportKML(item)"
                        @change="handleAction('change', itemIndex, $event)"
                        @delete="handleAction('delete', itemIndex, $event)"
                      />
                    </div>
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  textFallbackAlways
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem class="flex">
                    <router-link
                      class="font-bold text-blue-600 capitalize"
                      :to="{
                        name: 'ViewServiceArea',
                        params: { id: item.id },
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                    <div
                      v-if="item.is_default_service_area_for_swapping"
                      class="flex relative w-5 h-5 ml-2"
                    >
                      <span
                        class="inline-flex absolute w-full h-full bg-green-500 rounded-full opacity-75 animate-ping"
                      />
                      <span
                        class="inline-flex relative mt-1 ml-1 w-3 h-3 rounded-full bg-oGreen"
                      />
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <x-status
                      :variant="item.is_active ? 'green' : 'gray'"
                      :text="item.is_active ? 'Active' : 'Inactive'"
                      profile="fleet"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <div class="flex items-center">
                      <oto-edit-icon @click="edit(item)" />
                      <MoreActionsDropdown
                        :key="`more-actions-${itemIndex}`"
                        :type="`serviceArea`"
                        :data="item"
                        :isMakeDefault="true"
                        @exportKML="handleExportKML(item)"
                        @change="handleAction('change', index, $event)"
                        @delete="handleAction('delete', index, $event)"
                      />
                    </div>
                  </FSTableRowItem>
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Id</div>
                      <div class="col-span-5 right-text">
                        <router-link
                          class="font-bold text-blue-600 capitalize"
                          :to="{
                            name: 'ViewServiceArea',
                            params: { id: item.id },
                          }"
                        >
                          #{{ item.id | onlyLastFive }}
                        </router-link>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Assigned Fleet</div>
                      <div class="col-span-5 right-text">
                        {{ item.fleet ? item.fleet.name : '--' }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Vehicles Inside</div>
                      <div class="col-span-5 right-text">
                        {{ item.vehicles_inside }}
                      </div>
                    </div>
                  </td></FSTableRow
                >
              </template>
            </template>
          </template>
          <template v-if="layoutView === 'grid'">
            <FSTableRow
              v-for="(item, itemIndex) in data"
              :key="itemIndex"
              text-fallback-always
            >
              <FSTableGridItem>
                <div
                  class="bg-gray-50 rounded-lg flex justify-between items-center p-1"
                >
                  <div class="flex">
                    <router-link
                      class="font-bold text-blue-600 capitalize"
                      :to="{
                        name: 'ViewServiceArea',
                        params: { id: item.id },
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                    <div
                      v-if="item.is_default_service_area_for_swapping"
                      class="flex relative w-4 h-4 ml-2 mt-1"
                    >
                      <span
                        class="inline-flex absolute w-full h-full bg-green-500 rounded-full opacity-75 animate-ping"
                      />
                      <span
                        class="inline-flex relative mt-1 ml-1 w-2 h-2 rounded-full bg-oGreen"
                      />
                    </div>
                  </div>
                  <div class="flex items-center">
                    <oto-edit-icon @click="edit(item)" />
                    <MoreActionsDropdown
                      :key="`more-actions-${itemIndex}`"
                      :type="`serviceArea`"
                      :data="item"
                      :isMakeDefault="true"
                      @exportKML="handleExportKML(item)"
                      @change="handleAction('change', itemIndex, $event)"
                      @delete="handleAction('delete', itemIndex, $event)"
                    />
                  </div>
                </div>
                <div class="h-52">
                  <MapView :data="item" />
                </div>
              </FSTableGridItem>
            </FSTableRow>
          </template>
        </template>
      </FSTable>
    </content-section>
  </section>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import XStatus from '@/components/badge/XStatus'

import { ServiceAreaConfig } from '@/config/ServiceAreaConfig'
import { EventBus } from '@/utils/EventBus'
import SummaryCard from '@/components/cards/SummaryCard'
import AddEditServiceArea from './AddEditServiceArea'
import MapView from '@/components/service-area/MapView'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableGridItem,
} from '@/components/fs-table'
import {
  parseToOverlay,
  createKML,
  downloadKMLFile,
} from '@/composites/geofence/shared/geofence'
export default {
  name: 'ServiceAreas',
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    endpoint: {
      type: String,
      default: ServiceAreaConfig.api.index,
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '?' }),
    },
    isShowOnlyPageTitle: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ContentSection,
    TitlePlus,
    OtoEditIcon,
    SummaryCard,
    XStatus,
    MoreActionsDropdown: () =>
      import('@/composites/geofence/shared/MoreActionsDropdown'),
    OtoPageTitle: () => import('@/components/ui/OtoPageTitle'),
    AddEditServiceArea,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableGridItem,
    MapView,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      opened: [],
      layoutView: 'list',
      indexMetaData: {
        summary: {
          total: 0,
        },
        count: {
          total: 0,
        },
      },
      indexDataEndpoint: ServiceAreaConfig.api.index,
      tableHeaders: [
        {
          text: `${this.$t(
            'components.serviceAreaManagement.table.columns.id'
          )}`,
          width: '10%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.serviceAreaManagement.table.columns.name'
          )}`,
          width: '20%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.serviceAreaManagement.table.columns.assignedFleet'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.serviceAreaManagement.table.columns.vehiclesInside'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.serviceAreaManagement.table.columns.status'
          )}`,
          width: '10%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.serviceAreaManagement.table.columns.actions'
          )}`,
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '5%', sort: null },
        {
          text: `${this.$t(
            'components.serviceAreaManagement.table.columns.name'
          )}`,
          width: '30%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.serviceAreaManagement.table.columns.status'
          )}`,
          width: '20%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.serviceAreaManagement.table.columns.actions'
          )}`,
          width: '20%',
          sort: null,
        },
      ],
    }
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    window.addEventListener(ServiceAreaConfig.events.refresh, () => {
      this.$store.dispatch('fsTable/fetchData')
    })
  },
  methods: {
    parseToOverlay,
    createKML,
    downloadKMLFile,
    onResize() {
      this.windowWidth = window.innerWidth
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize)
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    add: function() {
      EventBus.$emit(ServiceAreaConfig.events.editingData, {
        // enable status-switch by default
        should_throttle: false,
        should_trigger: true,
        reset_speed_mode_for_exiting: false,
        speed_limit: '96d7b891-9a24-4228-ae5c-f8c456020544',
      })
      dispatchEvent(new Event(ServiceAreaConfig.events.sorToggle))
    },
    edit: async function(item) {
      var serviceAreaDetails = await this.$http
        .get(ServiceAreaConfig.api.details(item.id))
        .then((res) => res.data)
        .catch((err) => console.log(err))
      if (serviceAreaDetails == undefined) return

      delete serviceAreaDetails.created_at
      delete serviceAreaDetails.updated_at
      delete serviceAreaDetails.created_by
      delete serviceAreaDetails.organization

      serviceAreaDetails.existing_fleet = serviceAreaDetails.fleet
      serviceAreaDetails.fleet = serviceAreaDetails.fleet
        ? serviceAreaDetails.fleet.id
        : null
      serviceAreaDetails.speed_limit = serviceAreaDetails.speed_limit.id

      EventBus.$emit(ServiceAreaConfig.events.editingData, serviceAreaDetails)
      dispatchEvent(new Event(ServiceAreaConfig.events.sorToggle))
    },
    getActionsEndpoint(id) {
      return ServiceAreaConfig.api.update(id)
    },
    handleAction(type, index, data) {
      this.$store.dispatch('fsTable/fetchData')
      console.log('service-area', type, index, data)
      if (type === 'change') {
        this.$notify({
          type: `success`,
          group: `generic`,
          title: `Status`,
          text: `Service Area Status has been changed`,
        })

        return
      }

      if (type === 'delete') {
        const name = this.indexData[index].name || '--'

        this.$notify({
          type: `success`,
          group: `generic`,
          title: `Service area deleted`,
          text: `${name} service area has been deleted`,
        })

        return
      }
    },
    handleExportKML(data) {
      const coordinates = this.parseToOverlay(data.coords)

      const kmlString = this.createKML(coordinates)

      this.downloadKMLFile(data.name, kmlString)
    },
  },
}
</script>

<style>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
