<template>
  <div class="h-full">
    <gmap-map
      ref="map"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      map-type-id="terrain"
      style="width: 100%; height: 100%;"
    >
    </gmap-map>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
export default {
  name: 'MapView',

  props: {
    data: {
      required: true,
    },
    mapAreaType: {
      type: String,
      default: 'generic',
      required: true,
    },
  },

  data: () => ({
    map: null,
    zoom: 15,
    center: {
      lat: 10,
      lng: 10,
    },
    polygons: [],
    drawingOptions: {
      fillColor: '#64748b',
      fillOpacity: 0.3,
      strokeWeight: 2,
      strokeColor: '#0f172a',
      clickable: false,
      draggable: false,
      editable: false, // TODO: make this work with wrapper func
      zIndex: 1,
    },
    se_drawingOptions: {
      fillColor: '#4aff5c20',
      fillOpacity: 0.5,
      strokeWeight: 2,
      strokeColor: '#00b44d',
      clickable: false,
      draggable: false,
      editable: false, // TODO: make this work with wrapper func
      zIndex: 1,
    },
    p_drawingOptions: {
      fillColor: 'rgba(30, 152, 254, 0.13)',
      fillOpacity: 0.5,
      strokeWeight: 2,
      strokeColor: '#00acff',
      clickable: false,
      draggable: false,
      editable: false, // TODO: make this work with wrapper func
      zIndex: 1,
    },
    r_drawingOptions: {
      fillColor: 'rgba(255, 0, 0, 0.13)',
      fillOpacity: 0.5,
      strokeWeight: 2,
      strokeColor: '#ff0000',
      clickable: false,
      draggable: false,
      editable: false, // TODO: make this work with wrapper func
      zIndex: 1,
    },
    sl_drawingOptions: {
      fillColor: 'rgba(255, 186, 64, 0.13)',
      fillOpacity: 0.5,
      strokeWeight: 2,
      strokeColor: '#ff7c10',
      clickable: false,
      draggable: false,
      editable: false, // TODO: make this work with wrapper func
      zIndex: 1,
    },
  }),

  computed: {
    google: gmapApi,
    mapData: function() {
      return this.data
    },
  },

  created() {
    this.mapOptions = {
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUI: false,

      styles: [
        {
          featureType: 'all',
          elementType: 'geometry.fill',
          stylers: [
            {
              weight: '2.00',
            },
          ],
        },
        {
          featureType: 'all',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#9c9c9c',
            },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [
            {
              color: '#f2f2f2',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: 45,
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#eeeeee',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#7b7b7b',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [
            {
              color: '#46bcec',
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#c8d7d4',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#070707',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
      ],
    }
  },

  watch: {
    mapData: {
      deep: true,
      immediate: true,
      handler() {
        this.initDraw()
      },
    },
  },

  methods: {
    parseToOverlay(coords) {
      if (!coords) return
      var coordsMorph = coords.replaceAll('{', '')
      coordsMorph = coordsMorph.replaceAll('}', '')
      var coordsArr = coordsMorph.split(',')
      if (!coordsArr.length % 2 === 0) {
        if (coordsArr[coordsArr.length - 1] == ',') {
          coordsArr.pop()
        }
      }
      var payload = []
      for (var i = 0; i < coordsArr.length; i = i + 2) {
        const lat = parseFloat(coordsArr[i])
        const lng = parseFloat(coordsArr[i + 1])
        if (isNaN(lat) || isNaN(lng)) continue
        payload.push({
          lat: lat,
          lng: lng,
        })
      }
      return payload
    },
    setOverlayOnMap(payload, type) {
      var options = {}
      if (type == 'generic') {
        options = this.drawingOptions
      } else if (type == 'service') {
        options = this.se_drawingOptions
      } else if (type == 'parking') {
        options = this.p_drawingOptions
      } else if (type == 'restricted') {
        options = this.r_drawingOptions
      } else if (type == 'slow_zone') {
        options = this.sl_drawingOptions
      }
      this.parsedPolygon = new this.google.maps.Polygon({
        paths: payload,
        ...options,
      })
      this.parsedPolygon.setMap(this.map)

      this.polygons.push(this.parsedPolygon)
    },
    setMapCenterToDrawingCenter(paths, panToCenter = true) {
      var bounds = new this.google.maps.LatLngBounds()
      for (const path of paths) {
        bounds.extend(path)
      }
      const newCenter = bounds.getCenter()
      if (panToCenter) {
        this.map.panTo(newCenter)
      } else {
        this.center.lat = newCenter.lat()
        this.center.lng = newCenter.lng()
      }
      this.map.fitBounds(bounds)
    },
    initDraw() {
      var self = this
      setTimeout(() => {
        if (!self.$refs.map) return
        self.$refs.map.$mapPromise.then((map) => {
          self.map = map
          if (self.mapData != null) {
            const overlay = self.parseToOverlay(this.mapData.coords)
            self.setOverlayOnMap(overlay, this.mapAreaType)
            self.setMapCenterToDrawingCenter(overlay, false)
          }
        })
      }, 1000)
    },
  },
}
</script>
